import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import landpurchaseloan from "../images/land purchase loan.jpeg"

function LandPloan() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <ScrollToTop smooth top="100" color="maroon"/>

            <div className="homeloannadia">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Land Purchase loan</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mt-3">
                        <div className="card">

  <div className="card-body">
    <h5 className="card-title">LAND PURCHASE LOAN</h5>

 <p className="card-text"><b>1. Eligibility criteria:</b>

</p>
<p className='mb-2'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Applicant must be either salaried or self-employed.
</small></p>
<p className='mb-2'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Applicant must be above 21 years old while applying for the loan. 
</small></p>
<p className='mb-2'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Salaried individuals should show a minimum income of Rs. 12,000 p.m.
</small></p>
<p className='mb-4'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Self-employed individuals must present a minimum business income of Rs.2.4 Lakh p.a.</small></p>

<p className="card-text"><b>What are the Documentation Requirements and Fees for getting a Land Purchase Loan? </b></p>
<p>To make the loan documentation process faster for a Land Loan, these are the required documents: 

</p>
    <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Signed and filled application form with photographs. 
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg>  Some identity proof- passport, PAN card, voter ID, etc.
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Some address proof- voter id, ration card, driving license, passport, etc. 
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Educational qualification- most recent degree certificate. </small></p>

<p><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Housing documents- approved plans, title documents etc. </small></p>


    <a href="applyloan" className="buttonapply">Apply Now</a>
  </div>
</div>
                        </div>
                        <div className="col-md-3 mt-3">
                        <div className="card">
  <img src={landpurchaseloan} className="card-img-top" alt="landpurchaseloan"/>
  <div className="card-body">
    <p className="card-title"><a href='tel: 8927641891'>Help Line No. +91 8927641891</a></p>

  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>

<br/>
<br/>
        </div>
    )
}

export default LandPloan