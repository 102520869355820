import React from 'react'
import Navbar from '../inc/Navbar'
import "./Home.css";
import Slider from '../inc/Slider';

import Homeloan from "../images/Home Loan.jpg";
import Landpurchaseloan from "../images/Land Purchase Loan.jpg";
import businessloan from "../images/Business Loan photo.jpg";
import Annualfarmingloan from "../images/Animal Framing Loan.jpg";
import propertypurchaseloan from "../images/Property Purchase Loan.jpg";
import cowfarming from "../images/Animal farm loan.jpg"
import ScrollToTop from "react-scroll-to-top";
import ccloan from "../images/ccloan.jpg";
import Mortgageloan from "../images/Mortgage loan.jpg";
import btloan from "../images/bt Bank.jpg"
import Banklogo from "../pages/Banklogo";
import SALARYholderloan from "../images/DEFAULT GVT EMPLOYEE.jpg";
import ClientReviews from './ClientReviews';


function Home() {
  return (
    <div>
      <Navbar />

      <br />
      <br />
      <div className="album py-1">
        <div className="container">
          <h5>shyamoly Fincare</h5>
        </div>
      </div>
      <Slider />
      <ScrollToTop smooth top="100" color="maroon" />
      <div className='loanservicenadia'>
        <marquee>
          <div className="album py-1">
            <div className="container">
              <h5 style={{ color: "white" }} className='mt-2'>Welcome to shyamoly Fincare. We are offering -- HOUSE LOAN, LAND PERCHED LOAN, MORTGAGE LOAN, C.C LOAN ( SECURE & UNSECURED), GST C.C LOAN, UNSECURED BUSINESS LOAN, ANIMAL FARMING LOAN, B.T TOP UP LOAN, PROJECT LOAN, LOW CIBIL GVT EMPLOYEE LOAN ( SBI & PNB SALARY ACCOUNT) , LAND MORTGAGE LOAN ETC..</h5>
            </div>
          </div>
        </marquee>

      </div>


      <br />

      <div className="album py-1">
        <div className="container">
          <h1 style={{ color: "darkBlue" }}>Partner with Multiple BANK 's & N.B.F.C's</h1>

        </div>
      </div>
      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mt-3">
              <a href='Home-loan'>
                <div className="card" >
                  <img src={Homeloan} className="card-img-top" alt="homeloan" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">Home Loan</h5>

                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <a href='land-purchase-loan'>
                <div className="card" >
                  <img src={Landpurchaseloan} className="card-img-top" alt="Landpurchaseloan" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">Land Purchase Loan</h5>

                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card" >
                <img src={propertypurchaseloan} className="card-img-top" alt="propertypurchaseloan" />
                <div className="card-body">
                  <h5 className="card-title mb-3">Property Purchase Loan</h5>

                  <a href="#" className="homebutton">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <a href='business-loan'>
                <div className="card" >
                  <img src={businessloan} className="card-img-top" alt="businessloan" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">Business Loan</h5>

                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <a href='animal-farming-loan'>
                <div className="card" >
                  <img src={Annualfarmingloan} className="card-img-top" alt="Annualfarmingloan" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">Animal Framing Loan</h5>

                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <a href='Cowfarming'>
              <div className="card" >
                <img src={cowfarming} className="card-img-top" alt="cowfarmingloan" />
                <div className="card-body">
                  <h5 className="card-title mb-3">Cow Framing Loan</h5>

                  <a href="Cowfarming" className="homebutton">Learn More</a>
                </div>
                
              </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <a href='cc-loan'>
                <div className="card" >
                  <img src={ccloan} className="card-img-top" alt="ccloan" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">CC Loan</h5>

                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <a href='mortgage-loan'>
                <div className="card" >
                  <img src={Mortgageloan} className="card-img-top" alt="Mortgageloan" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">Mortgage Loan</h5>
                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <a href='bttopup-loan'>
                <div className="card" >
                  <img src={btloan} className="card-img-top" alt="btloan" />
                  <div className="card-body">
                    <h5 className="card-title mb-3">B.T TOP UP / Takeover Loan</h5>
                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-3 mt-3">
              <a href='Govt-holder'>
                <div className="card" >
                  <img src={SALARYholderloan} className="card-img-top" alt="SALARYholderloan"/>
                  <div className="card-body">
                    <h5 className="card-title mb-2">Default Govt. Employee Loan</h5>
                    <p className='mb-1'>( S.B.I & P.N.B Salary Holder)</p>
                    <center><p className='mb-2'><small>Low Cibil Accepted</small></p></center>

                    <button className="homebutton">Learn More</button>
                  </div>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>

      <br />
      <br />


      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card">

                <div className="card-body">

                  <p className="card-text">ব্যাঙ্ক বা ফাইনান্স থেকে খুব সহজভাবে লোন পেতে বা অন্যত্র প্রতারিত হলে আমাদের সাথে যোগাযোগ করুন</p>

                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">

                <div className="card-body">
                  <h5 style={{ color: "maroon" }} className="card-title">কেন আপনি Shyamoly Fincare নির্বাচন করবেন ?</h5>
                  <p className="card-text">১। আমরা প্রতিযোগিতামূলক সুদের হার প্রদান করি। <br />
                    ২। দ্রুত ঋণ আনুমোদন করি। <br />
                    ৩। বিস্তত ঋণের সীমার বিকল্পগুলি থেকে বেছে নিতে পারেন। <br />
                    ৪। সহজ ডকুমেন্ট এবং নমনীয় পরিশোধের বিকল্প। <br />
                    ৫। সারা দেশের হাজার গ্রাহকের বিশ্বস্ত প্রতিষ্ঠান। <br />
                    ৬। সমস্যা আপনার সমাধান আমাদের</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<br/>



      <div className='background2'>
        <div className="album py-1">
          <div className="container mt-3">
            <marquee>
              <h5 style={{ color: "white" }}>সমস্ত ব্যাঙ্ক, ফাইনান্স এর একমাত্র সঠিক ও বিশ্বস্ত ঠিকানা শ্যামলী ফিনকেয়ার। আমাদের এক্সপার্ট টিম আপনার প্রোফাইল বুঝে সঠিক গাইড ও সমাধান করবে। এটা আমাদের কমিটমেন্ট ।</h5>
            </marquee>
          </div>
        </div>
      </div>
      {/* <a href='tel: 9531781475'>
     <img src={model} className="d-block w-100" alt="model"/>
     </a> */}
      <div className="backgropundphoto">
        <center>
          <h1 style={{ color: "white" }}><b>SHAYAMOLY FINCARE </b></h1>
          <h6>Partner of Multiple Bank's & N.B.S.C's</h6><br />
          <a href='tel: +91 8927641891'>
            <button  className='buttonbook'>Book Appoinment </button>
          </a>
        </center>
      </div>
      <Banklogo />
      <br />
      
      <div className="album py-1">
      <div className='container'>
        <h2><b style={{color:"grey"}}>Our Customer</b> Reviews</h2>

      </div>
      </div>
<ClientReviews/>
<br/>
    </div>
  )
}

export default Home