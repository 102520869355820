import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import ccloanphoto from "../images/ccloan.jpg"

function Ccloan() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
      
        
<br/>
<br/>
        

        
        <ScrollToTop smooth top="100" color="maroon" />

<div className="homeloannadia">
    <div className="album py-1">

        <div className="container mt-3">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">CC loan</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<br />

<div className="album py-1">
    <div className="container">
        <div className="row">
            <div className="col-md-9 mt-3">
            <div className="card">

<div className="card-body">
<h5 className="card-title">CC LOAN</h5>
<p className='texts'>You always have to be on the lookout for ways to meet your capital requirements when you own a business. Capital is an integral element of any business operation, and the lack of it can limit your enterprise's growth. To ensure that you have sufficient funds to run your business seamlessly, you can rely on external financial assistance. One such popular form of financial aid is cash credit. Continue reading to learn more about cash credit's meaning and uses for business</p>

<p className="card-text"><b>What is meant by Cash credit?</b></p>
<p  className='texts'>Cash credit is referred to as short-term funding or loan for a company so that it can meet its working capital requirements. Cash credit is a sort of loan that is offered to businesses by financial institutions like banks. Banks offer cash credit to businesses based on the latter's credit history and financial stability. If you procure funds via cash credit, you can use it for various business-related purposes like expansion, purchasing plant and machinery, raw materials, hiring staff, debt consolidation, etc</p>
<p className='texts'>If you want to avail of a Working Capital Loan like cash credit, you have to hypothecate your stocks and receivables. You can repay the loan amount in a tenure of up to 12 months that can be renewed. As a business, you can only borrow up to the sanctioned limit from the lender. You also need to submit collateral or security to avail of Cash Credit. Once you avail of the funds, it can instantly help you manage any cash crunch in your business activities.</p>


<p><b>What are the features of cash credit?</b></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> It is a short-term loan with a repayment period of up to 12 months.
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> The interest rate is charged only on the amount withdrawn and not on the total sanctioned limit.

</small></p>

<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg>You can withdraw money as many times as required from within the sanctioned limit.</small></p>

<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg>It is only offered if you provide collateral or security.
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg>If your business has a higher credit score and repayment history, you will be able to avail a higher limit via cash credit.
</small></p>
<p className='mb-4'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
<path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
<path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg>Banks and financial institutions consider your business' turnover and volume while deciding the limit sanctioned.
</small></p>









<a href="applyloan" className="buttonapply">Apply Now</a>
</div>
</div>
            </div>
            <div className="col-md-3 mt-3">
            <div className="card">
<img src={ccloanphoto} className="card-img-top mb-2" alt="ccloanphoto"/> 

<div className="card-body">
<p className="card-title"><a href='tel: 8927641891'>Help Line No. +91 8927641891</a></p>

</div>
</div>
            </div>
        </div>
    </div>
</div>

<br/>
<br/>
        <br/>
    </div>
  )
}

export default Ccloan