import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import animalfarming from "../images/ANIMAL FARMING.jpg"
import animalfarmingloan from "../images/ANIMAL FARMING LOAN.jpg";
import btloanphoto from "../images/bt loan.jpg"

function BtLoan() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />


            <ScrollToTop smooth top="100" color="maroon" />

            <div className="homeloannadia">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">B.T TOP UP / Takeover loan</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mt-3">
                        <div className="card">

  <div className="card-body">
    <h5 className="card-title">B.T TOP UP / TAKEOVER LOAN</h5>
    <p className='texts'>A Balance Transfer (BT) Top Up Loan allows you to consolidate your existing debts or loans into a single loan with additional funds on top. Whether you're looking to manage high-interest debts more effectively or need extra funds for personal or business expenses, our BT Top Up Loan offers competitive rates and flexible repayment options to suit your financial needs.</p>

    <p className="card-text">1. Benefits of BT Top Up Loan</p>
    <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Consolidate multiple debts into one manageable payment.
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"goldenrod"}} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Lower your overall interest rates and save on finance charges.
</small></p>
<p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Access additional funds for various purposes without additional collateral.
</small></p>

<p className='mb-4'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{color:"goldenrod"}} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Flexible repayment terms tailored to your budget and financial goals.
</small></p>



 <p className="card-text mb-2"><b>2. Eligibility and Criteria</b></p>
 <p className='mb-4'>To qualify for our BT Top Up Loan, you typically need a good credit history and stable income. Eligibility criteria may vary based on the lender's policies. Contact us to discuss your specific financial situation and learn more about our application process.</p>


 <p className="card-text mb-2"><b>3. Guide to Take Over Loans</b></p>
 <p className='texts mb-4'>Explore our guide to BT Top Up Loans, covering important topics such as understanding balance transfers, maximizing savings through consolidation, and managing your finances effectively with this loan option.</p>

 



    <a href="applyloan" className="buttonapply">Apply Now</a>
  </div>
</div>
                        </div>
                        <div className="col-md-3 mt-3">
                        <div className="card">
  <img src={btloanphoto} className="card-img-top mb-2" alt="btloanphoto"/> 
  <div className="card-body">
  <p className="card-title"><a href='tel: 8927641891'>Help Line No. +91 8927641891</a></p>

  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>

<br/>
<br/>
        </div>
    )
}

export default BtLoan