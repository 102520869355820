import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import MortgageLoanimage from "../images/mortgage loan image.jpeg"
import MortgageLoanphoto from "../images/Mortgage loan photo.jpg";
import MortgageLoanimg from "../images/business loan.jpeg"
import MortgageLoanservice from "../images/loan service.jpeg";
import MortgageLoanservicenadia from "../images/business.jpeg";
import MortgageP from './MortgageP';


function MortgageLoan() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />


      <ScrollToTop smooth top="100" color="maroon" />

      <div className="homeloannadia">
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Mortgage loan</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br />

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-9 mt-3">
              <div className="card">

                <div className="card-body">
                  <h5 className="card-title">MORTGAGE LOAN</h5>




                  <p className='mb-2'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> To build a new home
                  </small></p>
                  <p className='mb-2'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> To buy a built home
                  </small></p>
                  <p className='mb-2'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} class="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> For reconstruction
                  </small></p>
                  <p className='mb-2'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} class="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> For repairing, renovation or other random construction work (Repair Loan)</small></p>
                  <p className='mb-4'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} class="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> To transfer a loan taken from another financial organaization at a higher interest rate. </small></p>

                  <hr />
                  <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "goldenrod" }} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> Any individual working in a Government, Semi-Government or Private company
                  </small></p>
                  <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "goldenrod" }} fill="currentColor" class="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg>  Doctor's Chartered Accountants and other service professionals can avail home loans
                  </small></p>
                  <p className='mb-1'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> Big and small revenue earners, businessmen as well as labour class individual.
                  </small></p>
                  <p className='mb-4'><small><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "goldenrod" }} className="bi bi-check2-all me-2" viewBox="0 0 16 16">
                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                  </svg> Income tax paying and non-paying self-employed individual, businessmen and shopkeepers.  </small></p>




                  <a href="applyloan" className="buttonapply">Apply Now</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={MortgageLoanimage} className="card-img-top mb-2" alt="MortgageLoan" />
                <img src={MortgageLoanphoto} className="card-img-top mb-2" alt="MortgageLoan" />
    
                <div className="card-body">
                  <p className="card-title"><a href='tel: 8927641891'>Help Line No. +91 8927641891</a></p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     

      <MortgageP/>
      <br/>
    </div>
  )
}

export default MortgageLoan